import React from 'react';
import ReactDOM from 'react-dom';


import {Link} from "react-router-dom";

export class NavOverlay extends React.Component {
	static defaultProps = {
		close: () => {},
		routes: [],
	}
	render() {
		return ReactDOM.createPortal(
			<div
				style={styles.top}
				className='NavOverlay'
			>
				<div
					style={{display: 'flex', justifyContent: 'flex-end', color: 'white',}}
				>
					<span style={styles.close} onClick={this.props.close}>close</span>
				</div>
				<div style={styles.content}>
					{this.props.routes.map((obj, i) => {
						return (
							<Link to={obj.href} onClick={this.props.close} key={i} style={styles.a}>{obj.text}</Link>
						)
					})}
				</div>
			</div>,
			document.body
		)
	}
}

const styles = {
	top: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.95)',
		top: '0px',
		display: 'flex',
		flexDirection: 'column',
	},
	close: {
		cursor: 'pointer',
		boxSizing: 'border-box',
		padding: '7px',
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		flexDirection: 'column',
	},
	a: {
		textDecoration: 'none',
		fontSize: '21px',
		boxSizing: 'border-box',
		padding: '7px',
	}
};
