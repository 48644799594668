import React from 'react';

import '../css/FontStyle.css';

// var ReactFitText = require('react-fittext');
// var FitText = require('./FitText.jsx');
import {FitText} from './FitText.jsx';


export class FontStyle extends React.Component {
	reference = null;

	state = {
		height: 0,
	}
	static defaultProps = {
		compressor: null,
		maxFontSize: null,
		minFontSize: null,
		setHeight: () => {},
		style: {},
		text: '',
	}
	componentDidMount() {
		let height = this.reference.clientHeight;
		this.setState({
			height: height,
		});
		this.props.setHeight(height);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.height !== this.state.height) {
			this.props.setHeight(this.state.height);
		}
	}
	afterResize = () => {
		if (this.reference !== null) {
			this.setState({
				height: this.reference.clientHeight,
			})
		}
	}
	render() {
		let pass = {};
		if (this.props.maxFontSize !== null) {
			pass = {...pass, maxFontSize: this.props.maxFontSize};
		}
		if (this.props.minFontSize !== null) {
			pass = {...pass, minFontSize: this.props.minFontSize};
		}
		if (this.props.compressor !== null) {
			pass = {...pass, compressor: this.props.compressor};
		}
		return (
			<div ref={eref => this.reference = eref} style={{...styles.cursive, ...this.props.style}}>
				<FitText afterResize={this.afterResize} {...pass}>
					<div>{this.props.text}</div>
				</FitText>
			</div>
		)
	}
}

const styles = {
	cursive: {
		fontFamily: 'impact',
		color: 'white',
		fontSize: '50px',
	}
};
