import React from 'react';

import '../css/Header.css';
import logoSrc from '../assets/BFR Grey.png';

// import {FontStyle} from './FontStyle.jsx';
import {NavOverlay} from './NavOverlay.jsx';
import {ThreeHorizontalLines} from './ThreeHorizontalLines.jsx';

import {Link} from "react-router-dom";
export class Header extends React.Component {
	headerRef = null;

	state = {
		nw: 0,
		nh: 0,
		windowInnerWidth: window.innerWidth,
		imgWidth: window.innerWidth <= 520 ? 40 : 70,
		headerHeight: 0,
		routes: this.props.routes,
		showNavOverlay: false,
	}
	static defaultProps = {
		routes: [],
		setHeaderHeight: () => {},
		history: null,
	}
	componentDidMount() {
		window.addEventListener('resize', this.onResize);

		// let unlisten = this.props.history.listen(({ action, location }) => {
		// 	// The current location changed.
		// 	console.log('location changed');
		//   });
		  
		// // Later, when you are done listening for changes...
		// unlisten();


		let headerHeight = this.headerRef.clientHeight;
		this.props.setHeaderHeight(headerHeight);
		this.setState({
			headerHeight,
		})
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.imgWidth !== this.state.imgWidth || prevState.headerHeight !== this.state.headerHeight) {
			this.props.setHeaderHeight(this.state.headerHeight);
		}
	}
	onNavPopOut = e => {
		this.setState({
			showNavOverlay: true,
		})
	}
	onNavPopOutClose = obj => {
		this.setState({
			showNavOverlay: false,
		})
	}
	onResize = e => {
		this.setState({
			imgWidth: window.innerWidth <= 520 ? 40 : 70,
			windowInnerWidth: window.innerWidth,
			showNavOverlay: window.innerWidth >= 520 ? false : this.state.showNavOverlay,
			headerHeight: this.headerRef.clientHeight,
		})
	}
	imageLogoLoad = e => {
		this.setState({
			nw: e.target.naturalWidth,
			nh: e.target.naturalHeight,
			headerHeight: this.headerRef.clientHeight,
		})
	}
	getImgHeight = (nw, nh, width) => {
		let result = width * nh / nw;
		return result;
	}
	render() {
		let imageHeight = this.getImgHeight(this.state.nw, this.state.nh, this.state.imgWidth),
			widthBoolean = this.state.windowInnerWidth >= 520,
			topStyle = widthBoolean ? {} : {display: 'flex', alignItems: 'center',};
		return (
			<div className='header' style={styles.header} ref={eref => this.headerRef = eref}>{/*header*/}
				<div>{/*nav*/}
					<div style={styles.topRow}>
						<div style={{...topStyle,flex: 1,}}>
							{widthBoolean ?
								// this.state.routes.map((obj, i) => <a href={obj.href} key={i} style={styles.a}>{obj.text}</a>)
								this.state.routes.map((obj, i) => {
									return (
										<Link to={obj.href} key={i} style={styles.a}>{obj.text}</Link>
									)
								})

								:
								<ThreeHorizontalLines onClick={this.onNavPopOut} />
							}

						</div>
						<img alt='' onClick={() => window.location = '/#'} onLoad={this.imageLogoLoad} style={{padding: '5px', cursor: 'pointer', width: `${this.state.imgWidth}px`, height: `${imageHeight}px`}} src={logoSrc} />
					</div>
					
					{this.state.showNavOverlay &&
						<NavOverlay routes={this.state.routes} close={this.onNavPopOutClose} />
					}
				</div>
			</div>
		)
	}
}

const styles = {
	header: {
		paddingTop: '10px',
		paddingBottom: '10px',
		// position: 'absolute',
		// top: '0px',
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		// display: 'flex',
	},
	a: {
		textDecoration: 'none',
		fontSize: '21px',
		boxSizing: 'border-box',
		paddingLeft: '7px',
		paddingRight: '7px',
	},
	headerText: {
		fontFamily: 'impact',
		color: 'white',
		display: 'flex',
		minHeight: '19px',
		// flexDirection: 'row',
		// alignItems: 'center',
	},
	imgObj: {
		cursor: 'pointer',
		width: 70,
	},
	topRow: {
		display: 'flex',
		justifyContent: 'flex-end',
	}
};
