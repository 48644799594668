import React from 'react';

import imgSrc from '../assets/IMG-1603.jpg';
// import img from '../assets/clipped.jpg';

import {FontStyle} from './FontStyle.jsx';

export class Main extends React.Component {
	constructor(props) {
		super(props);
		//
		let nw = 6000,																	//got naturalWidth and naturalHeight by observing values after rendering once - maybe try to dynamically add this in when file is uploaded as part of image meta data
			nh = 4000,
			ww = window.innerWidth,
			wh = window.innerHeight,
			mwFlag = this.getMinWidth(nw, nh, ww, wh - this.props.headerHeight) >= ww;
		//
		this.state = {
			height1: 0,
			height2: 0,
			naturalWidth: nw,
			naturalHeight: nh,
			windowInnerWidth: ww,
			windowInnerHeight: wh,
			// imgObj: mwFlag ? {} : styles.imgObj,
			mwFlag,
		}
	}
	static defaultProps = {
		headerHeight: 0,
	}
	componentDidMount() {
		window.addEventListener('resize', this.resize);

		this.img = new Image();
		this.img.onload = this.onImgLoad;
		this.img.src = imgSrc;
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
	}
	onImgLoad = e => {
		let nw = e.target.naturalWidth,
			nh = e.target.naturalHeight,
			mwFlag = this.getMinWidth(nw, nh, this.state.windowInnerWidth, this.state.windowInnerHeight - this.props.headerHeight) >= this.state.windowInnerWidth;
		//
		this.setState({
			naturalWidth: nw,
			naturalHeight: nh,
			// imgObj: false ? {} : styles.imgObj,
			mwFlag,
		})
	}
	getMinWidth = (nw, nh, ww, wh) => {
		let result = wh * nw / nh;
		return result;
	}
	getMinHeight = (nw, nh, ww, wh) => {
		let result = ww * nh / nw;
		return result;
	}
	resize = e => {
		let ww = window.innerWidth,
			wh = window.innerHeight,
			mwFlag = this.getMinWidth(this.state.naturalWidth, this.state.naturalHeight, ww, wh - this.props.headerHeight) >= ww;
		this.setState({
			windowInnerWidth: ww,
			windowInnerHeight: wh,
			// imgObj: false ? {} : styles.imgObj,
			mwFlag,
		})
	}
	getImgDimensions = (nw, nh, ww, wh) => {
		return {
			width: nw > ww ? '100%' : nw,
			// height: 
			// width: nw > nh ? nw : 'auto',
			// height: nw < nh ? nh : 'auto',
		}
	}
	setHeight1 = height => {
		this.setState({
			height1: height,
		})
	}
	setHeight2 = height => {
		this.setState({
			height2: height,
		})
	}
	render() {
		return (
			<div
				style={{
					backgroundColor: 'rgb(40, 44, 52)',
				}}
			>{/*main*/}
				<div
					style={{
						height: this.state.mwFlag ?
							(this.getMinHeight(this.state.naturalWidth, this.state.naturalHeight, this.state.windowInnerWidth, this.state.windowInnerHeight - this.props.headerHeight))
							:
							`${this.state.windowInnerHeight - this.props.headerHeight}px`,
						overflow: 'hidden',
						...styles.imgObj,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						width: '100%',
						minHeight: `${this.state.height1 + this.state.height2}px`
					}}
				>

					
					<FontStyle setHeight={this.setHeight1} compressor={1.5} text='Precision, Protection, Suppression' maxFontSize={132} style={styles.fontStyle} />

					<FontStyle setHeight={this.setHeight2} text='Baze Firearm Resource' maxFontSize={132} style={styles.fontStyle} />
				</div>
				{/* <div style={{fontSize: '40px', color: 'white',}}>Under Development</div> */}
			</div>
		)
	}
}

const styles = {
	imgObj: {
		backgroundImage: `url(${imgSrc})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	fontStyle: {
		color: 'grey',
		position: 'relative',
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	}
};
