import React from 'react';

// import imgSrc from '../assets/IMG-1699.jpg';
import imgSrc from '../assets/270269426_497675991668167_3285521406644296207_n.jpg';

import {FontStyle} from './FontStyle.jsx';

import {CONTACTWIDTHWORDBREAK} from '../js/constants.js';

export class Contact extends React.Component {
	constructor(props) {
		super(props);
		//
		let nw = 6000,																	//got naturalWidth and naturalHeight by observing values after rendering once - maybe try to dynamically add this in when file is uploaded as part of image meta data
			nh = 4000,
			ww = window.innerWidth,
			wh = window.innerHeight,
			mwFlag = this.getMinWidth(nw, nh, ww, wh - this.props.headerHeight) >= ww;
		//
		this.state = {
			contactSpacer: ww <= CONTACTWIDTHWORDBREAK ? {} : styles.contactSpacer,
			contactStyle: ww <= CONTACTWIDTHWORDBREAK ? styles.contactOverflow : {},
			height1: 0,
			height2: 0,
			naturalWidth: nw,
			naturalHeight: nh,
			windowInnerWidth: ww,
			windowInnerHeight: wh,
			mwFlag,
		}
	}
	static defaultProps = {
		headerHeight: 0,
	}
	componentDidMount() {
		window.addEventListener('resize', this.resize);

		this.img = new Image();
		this.img.onload = this.onImgLoad;
		this.img.src = imgSrc;
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.resize);
	}
	onImgLoad = e => {
		let nw = e.target.naturalWidth,
			nh = e.target.naturalHeight,
			mwFlag = this.getMinWidth(nw, nh, this.state.windowInnerWidth, this.state.windowInnerHeight - this.props.headerHeight) >= this.state.windowInnerWidth;
		//
		this.setState({
			naturalWidth: nw,
			naturalHeight: nh,
			mwFlag,
		})
	}
	getMinWidth = (nw, nh, ww, wh) => {
		let result = wh * nw / nh;
		return result;
	}
	getMinHeight = (nw, nh, ww, wh) => {
		let result = ww * nh / nw;
		return result;
	}
	resize = e => {
		let ww = window.innerWidth,
			wh = window.innerHeight,
			mwFlag = this.getMinWidth(this.state.naturalWidth, this.state.naturalHeight, ww, wh - this.props.headerHeight) >= ww;
		this.setState({
			windowInnerWidth: ww,
			windowInnerHeight: wh,
			contactSpacer: ww <= CONTACTWIDTHWORDBREAK ? {} : styles.contactSpacer,
			contactStyle: ww <= CONTACTWIDTHWORDBREAK ? styles.contactOverflow : {},
			mwFlag,
		})
	}
	getImgDimensions = (nw, nh, ww, wh) => {
		return {
			width: nw > ww ? '100%' : nw,
		}
	}
	setHeight1 = height => {
		this.setState({
			height1: height,
		})
	}
	setHeight2 = height => {
		this.setState({
			height2: height,
		})
	}
	render() {
		const brBoolean = this.state.windowInnerWidth <= CONTACTWIDTHWORDBREAK,
			textShadow = {textShadow: '0px 0px 10px black, 0px 0px 10px black, 0px 0px 10px black, 0px 0px 10px black'};
		let contactStyle = {};

		if (this.state.windowInnerWidth <= 436 && this.state.windowInnerWidth > 403) {
			contactStyle = {fontSize: '26px',};
		}
		else if (this.state.windowInnerWidth <= 403 && this.state.windowInnerWidth > 388) {
			contactStyle = {fontSize: '25px',};
		}
		else if (this.state.windowInnerWidth <= 388 && this.state.windowInnerWidth > 372) {
			contactStyle = {fontSize: '24px',};
		}
		else if (this.state.windowInnerWidth <= 372 && this.state.windowInnerWidth > 356) {
			contactStyle = {fontSize: '23px',};
		}
		else if (this.state.windowInnerWidth <= 356 && this.state.windowInnerWidth > 342) {
			contactStyle = {fontSize: '22px',};
		}
		else if (this.state.windowInnerWidth <= 342 && this.state.windowInnerWidth > 325) {
			contactStyle = {fontSize: '21px',};
		}
		else if (this.state.windowInnerWidth <= 325 && this.state.windowInnerWidth > 311) {
			contactStyle = {fontSize: '20px',};
		}
		else if (this.state.windowInnerWidth <= 311 && this.state.windowInnerWidth > 294) {
			contactStyle = {fontSize: '19px',};
		}
		else if (this.state.windowInnerWidth <= 294 && this.state.windowInnerWidth > 279) {
			contactStyle = {fontSize: '18px',};
		}
		else if (this.state.windowInnerWidth <= 279 && this.state.windowInnerWidth > 264) {
			contactStyle = {fontSize: '17px',};
		}
		else if (this.state.windowInnerWidth <= 264 && this.state.windowInnerWidth > 248) {
			contactStyle = {fontSize: '16px',};
		}
		else if (this.state.windowInnerWidth <= 248 && this.state.windowInnerWidth > 232) {
			contactStyle = {fontSize: '15px',};
		}
		else if (this.state.windowInnerWidth <= 232 && this.state.windowInnerWidth > 217) {
			contactStyle = {fontSize: '14px',};
		}
		else if (this.state.windowInnerWidth <= 217 && this.state.windowInnerWidth > 201) {
			contactStyle = {fontSize: '13px',};
		}
		else if (this.state.windowInnerWidth <= 201) {
			contactStyle = {fontSize: '12px',};
		}

		return (
			<div
				style={{
					backgroundColor: 'rgb(40, 44, 52)',
				}}
			>
				<div
					style={{
						height: this.state.mwFlag ?
							(this.getMinHeight(this.state.naturalWidth, this.state.naturalHeight, this.state.windowInnerWidth, this.state.windowInnerHeight - this.props.headerHeight))
							:
							`${this.state.windowInnerHeight - this.props.headerHeight}px`,
						// overflow: 'hidden',
						...styles.imgObj,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						width: '100%',
						minHeight: `${this.state.height1 + this.state.height2}px`
					}}
				>

					<div
						style={{
							...styles.wrapper,
							// minWidth: '293px',
							// minHeight: ,
							display: 'flex',
							flexDirection: 'column',

						}}
					>
						<FontStyle setHeight={this.setHeight1} text='Contact Us'
							compressor={0.9}
							maxFontSize={132}
							style={{...styles.fontStyle, ...textShadow}}
						/>
						<div style={styles.contactInfo}>
							<div style={this.state.contactSpacer}>

							</div>
							<div
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: 'column',
									textAlign: 'left',
									...this.state.contactStyle,
									...contactStyle,
									...textShadow,
								}}
							>
								<div
									style={{
										display: 'flex',
										flex: 1,
										alignItems: 'flex-end',
										// boxShadow: '0px 0px 15px 5px white',
										// textShadow: '0px 0px 14px black, 0px 0px 10px black, 0px 0px 10px black'
									}}
								>
									<a style={styles.a} href="mailto:bazefirearmresource@gmail.com">Email:{brBoolean ? <br /> : <> </>}<span style={styles.underline}>bazefirearmresource@gmail.com</span></a>
								</div>
								<div
									style={{
										flex: 0.2,
									}}
								>
									Phone: (580)271-0945
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const styles = {
	a: {
		color: 'grey',
		textDecoration: 'none',
	},
	contact: {
		color: 'grey',
	},
	imgObj: {
		backgroundImage: `url(${imgSrc})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	fontStyle: {
		color: 'grey',
		position: 'relative',
		width: '100%',
	},
	wrapper: {
		// backgroundColor: 'rgba(0, 0, 0, 0.5)',
		height: '100%',
	},
	contactInfo: {
		color: 'gray',
		fontSize: '28px',
		fontWeight: 'bold',
		display: 'flex',
		flex: 1,
	},
	contactOverflow: {
		wordBreak: 'break-all',
		overflowWrap: 'anywhere',
	},
	contactSpacer: {
		flex: 1,
	},
	underline: {
		textDecoration: 'underline',
	},
}
