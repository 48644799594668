import React from 'react';

import '../css/ThreeHorizontalLines.css';

export class ThreeHorizontalLines extends React.Component {
	static defaultProps = {
		onClick: () => {},
	}
	render() {
		return (
			<div className='box-shadow-menu' onClick={this.props.onClick}>
				{/* <li></li> */}
			</div>
		)
	}
}
