// import logo from './logo.svg';
import React from 'react';
import './App.css';

import {Header} from './Components/Header.jsx';
import {Main} from './Components/Main.jsx';
import {Contact} from './Components/Contact.jsx';
import {Products} from './Components/Products.jsx';
import {About} from './Components/About.jsx';

import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";

// import { createBrowserHistory } from "history";

// const history = createBrowserHistory({ window });

// import testImg from './assets/clipped.jpg';

// let testing = false;

class App extends React.Component {
	state = {
		headerHeight: 0,
	}
	setHeaderHeight = (headerHeight) => {
		this.setState({
			headerHeight,
		})
	}
	render() {
		return (
			<div className="App" style={styles.app}>
				<BrowserRouter>
					<Header
						// history={history}
						routes={[
							{
								text: 'Home',
								href: '/',
							},
							{
								text: 'Contact',
								href: '/contact',
							},
							{
								text: 'Products',
								href: '/products',
							},
							{
								text: 'About Us',
								href: '/aboutus',
							}
						]}
						setHeaderHeight={this.setHeaderHeight}
					/>
				
					<Routes>
						<Route path='/' element={<Main headerHeight={this.state.headerHeight} />} />
						<Route path='contact' element={<Contact headerHeight={this.state.headerHeight} />} />
						<Route path='products' element={<Products headerHeight={this.state.headerHeight} />} />
						<Route path='aboutus' element={<About headerHeight={this.state.headerHeight} />} />
					</Routes>
				</BrowserRouter>
			</div>
		)
	}
}

const styles = {
  app: {
	width: '100%',
	height: '100%',
	backgroundColor: '#282c34',
	// overflow: 'auto',
  }
};

export default App;
